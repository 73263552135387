import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

import dynamic from "next/dynamic";
import { SayHelloButton } from "./SayHelloButton.Component";

const PriceOverview = dynamic(() => import("./PriceOverview.Component"), {
  ssr: false,
  loading: () => (
    <div className="w-full min-[325px]:h-[200px] min-[500px]:h-[300px] lg:h-[505px]" />
  ),
});

const Section1 = () => {
  return (
    <SectionWrap
      containerProps={{
        className:
          "bg-[linear-gradient(160.22deg,#14020F_34.2%,#000954_85.27%,#000F7D_117.09%)] pt-[7px]",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center sm:pt-1",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <SayHelloButton />
      <PriceOverview />
    </SectionWrap>
  );
};

export default Section1;
