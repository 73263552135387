import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NormalImage } from "../../../Components/Lib";
import superChargeIcon from "Assets/Svg/supercharge.svg";

const CardTitle1 = () => {
  return (
    <div className="flex flex-col items-start">
      <div>
        <Typography.SectionTitle className="inline-block">
          {localesText("home.what_if")}
        </Typography.SectionTitle>
        <RotateHighlightText
          content={localesText("home.analysis")}
          className="!px-2 ml-1 text-[26px] md:bg-clever-link  sm:bg-black leading-[36px] md:text-[56px] md:leading-[68px]"
        />
      </div>
      <Typography.Body className="text-lg text-start  text-clever-gray mt-[18px] lg:w-[80%] md:w-full">
        {localesText("home.forecast_your_sale")}
      </Typography.Body>
    </div>
  );
};

export default memo(CardTitle1);
