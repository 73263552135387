import CardInsights from "Assets/Lotties/card_insights.json";
import { RotateHighlightText, Typography } from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo, useRef } from "react";
import { localesText } from "Utils/LocalesText.Util";
import { motion, useTransform } from "framer-motion";
import { AnalysisCardProps } from "./AnalysisCard";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";

const InsightCard = ({
  progress,
  range,
  targetScale,
  i,
}: AnalysisCardProps) => {
  const container = useRef(null);

  const scale = useTransform(progress, range, [1, targetScale]);
  return (
    <div ref={container} className="md:mt-[100px] mt-10">
      <motion.div
        style={{
          scale,
          top: `calc(-5vh + ${i * 25}px)`,
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:bg-[url('/Assets/Images/Others/card_insights.webp')] bg-[url('/Assets/Images/Others/card_insights_mobile.webp')] bg-bottom bg-cover sm:bg-center rounded-[40px] px-6 xs:px-6 md:p-12 xl:px-[72px] lg:px-6 pb-0 pt-6 h-auto lg:h-[500px]">
          <div className="flex flex-col justify-center items-start">
            <div className="flex flex-col items-start">
              <div>
                <Typography.SectionTitle className="inline-block text-clever-black">
                  {localesText("home.actionable")}
                </Typography.SectionTitle>
                <RotateHighlightText
                  content={localesText("home.insights")}
                  className="!px-2 ml-1 text-[26px] md:bg-clever-link  sm:bg-black leading-[36px] md:text-[56px] md:leading-[68px]"
                />
              </div>
              <Typography.Body className="text-lg text-start mt-[18px] lg:w-[80%] md:w-full text-clever-black">
                {localesText("home.extract_the_most_meaningful")}
              </Typography.Body>
            </div>
            <NextLink href={PATHS_NAME.INSIGHT} target="_blank">
              <button className="mt-6 md:mt-8 lg:mt-[30px] rounded-full h-12 px-7 py-3 text-white bg-clever-primary flex items-center gap-[10px]">
                <Typography.Content className="font-semibold text-base">
                  {localesText("home.try_it_now")}
                </Typography.Content>
              </button>
            </NextLink>
          </div>

          <div className="flex justify-center items-center">
            <LottieImage options={{ animationData: CardInsights }} />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default memo(InsightCard);
