import { NextLink } from "Components/Lib/Next";
import { Button } from "Components/Own";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { classnames } from "Utils/Classnames.Util";
import { localesText } from "Utils/LocalesText.Util";
import { PAGE_THEME } from "Constants/Common.Constant";
import { useThemeContext } from "Context/Theme.Context";

export const SayHelloButton = () => {
  const { theme } = useThemeContext();

  return (
    <NextLink
      href={PATHS_NAME.GET_IN_TOUCH}
      onClick={() => {
        logAnalyticEvent({ key: TRACKING_EVENTS.usingTypeformData });
      }}
    >
      <Button
        className={classnames(
          "mb-4 sm:mb-5 h-12 rounded-full border-none bg-clever-green px-7 text-sm text-black",
          {
            "bg-clever-primary text-white": theme === PAGE_THEME.GRADIENT,
          },
        )}
      >
        {localesText("home.get_started")}
      </Button>
    </NextLink>
  );
};
