import { memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import Lottie, { LottieProps, Options } from "react-lottie";

import { useIsInViewport } from "Hooks/useIsInViewPort";
import { useSWR } from "Hooks/useSWR";
import { useTimeout } from "Hooks/useTimeout";

const defaultOptions = {
  loop: false,
  autoplay: false,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LottieImage = ({
  useObserver = true,
  loopCount, // milisecond and autotrigger when loopCount available
  lottieRef,
  options,
  ...rest
}: LottieProps & {
  useObserver?: boolean;
  loopCount?: number;
  lottieRef?: any;
  containerClassName?: any;
  options: Options & { url?: string };
}) => {
  const ref = useRef<any>(null);

  const { url, animationData, ...restOptions } = options;

  const { data, isLoading } = useSWR(!!url ? url : null);

  useImperativeHandle(lottieRef, () => ({
    play: () => ref.current?.play(),
    stop: () => ref.current?.stop(),
    pause: () => ref.current?.pause(),
  }));

  const timeoutRef = useTimeout(() => {
    ref.current?.stop();

    window.clearTimeout(timeoutRef.current);
  }, loopCount);

  if (isLoading) return <div className="h-1 w-1" />;

  if (useObserver) {
    return (
      <ObserverLottie
        lottieRef={ref}
        options={{
          ...restOptions,
          animationData: !!url ? data : animationData,
        }}
        {...rest}
      />
    );
  }

  return (
    <NormalLottie
      lottieRef={ref}
      options={{
        ...restOptions,
        animationData: !!url ? data : animationData,
      }}
      {...rest}
    />
  );
};

const ObserverLottie = ({
  options,
  lottieRef,
  containerClassName,
  ...rest
}: LottieProps & { lottieRef: any; containerClassName?: any }) => {
  const ref = useRef<any>(null);
  const [isPaused, setIsPaused] = useState(true);
  const [isAlreadyPlay, setIsAlreadyPlay] = useState(false);

  const isInViewport = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewport && isPaused && !isAlreadyPlay) {
      setIsPaused(false);
    }
  }, [isAlreadyPlay, isInViewport, isPaused]);

  return (
    <div className={containerClassName} ref={ref}>
      <Lottie
        ref={lottieRef}
        options={{ ...defaultOptions, ...options }}
        eventListeners={[
          {
            eventName: "complete",
            callback: () => {
              setIsPaused(true);
              setIsAlreadyPlay(true);
            },
          },
        ]}
        isPaused={isPaused || isAlreadyPlay}
        {...rest}
      />
    </div>
  );
};

const NormalLottie = ({
  options,
  lottieRef,
  ...rest
}: LottieProps & { lottieRef: any }) => {
  return (
    <Lottie
      ref={lottieRef}
      options={{ ...defaultOptions, ...options }}
      {...rest}
    />
  );
};

export default memo(LottieImage);
