import { memo } from "react";

import { NextImage, NextLink } from "Components/Lib/Next";
import { Button, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { localesText } from "Utils/LocalesText.Util";
import Marquee from "react-fast-marquee";

const ImageComponent = () => {
  const logos = [
    { src: "/Assets/Images/Others/home_deal_girl.webp", alt: "girl" },
    { src: "/Assets/Images/Others/home_deal_amr.webp", alt: "amr" },
    { src: "/Assets/Images/Others/home_deal_tyro.webp", alt: "tyro" },
    {
      src: "/Assets/Images/Others/home_deal_aftershock.webp",
      alt: "aftershock",
    },
    {
      src: "/Assets/Images/Others/cake_creation_logo.webp",
      alt: "cake-creation",
    },
  ];

  return (
    <div className="relative w-full bg-clever-white-slate rounded-[20px] md:rounded-[40px] p-8 md:p-[60px]">
      <div className="inline-flex w-full flex-nowrap overflow-hidden">
        <Marquee speed={100}>
          <div className="flex items-center justify-center md:justify-start">
            <div className="flex items-end gap-3">
              <NextImage
                quality={1}
                width={220}
                height={300}
                src={logos[0].src}
                alt={logos[0].alt}
              />

              <NextImage
                quality={1}
                width={135}
                height={135}
                src={logos[1].src}
                alt={logos[1].alt}
              />
            </div>

            <NextImage
              width={220}
              height={220}
              src={logos[2].src}
              alt={logos[2].alt}
            />

            <NextImage
              width={317}
              height={300}
              src={logos[3].src}
              alt={logos[3].alt}
            />

            <NextImage
              quality={1}
              width={320}
              height={320}
              src={logos[4].src}
              alt={logos[4].alt}
            />
          </div>
        </Marquee>
      </div>

      <div className="flex flex-col items-center mt-6">
        <Typography.Body className="text-[#878F9D] md:absolute">
          {localesText("home.download_our_app")}
        </Typography.Body>

        <NextLink href={PATHS_NAME.SHOPPERS} className="absolute -bottom-6">
          <Button className="rounded-full bg-clever-link px-7">
            {localesText("home.show_deals")}
          </Button>
        </NextLink>
      </div>
    </div>
  );
};

export default memo(ImageComponent);
