import CardAnalysis from "Assets/Lotties/card_analysis.json";
import { Typography } from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo, useRef } from "react";
import { localesText } from "Utils/LocalesText.Util";
import CardTitle1 from "./CardTitle1";
import { MotionValue, motion, useTransform } from "framer-motion";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";

export interface AnalysisCardProps {
  progress: MotionValue<number>;
  range: number[];
  targetScale: number;
  i: number;
}

const AnalysisCard = ({
  progress,
  range,
  targetScale,
  i,
}: AnalysisCardProps) => {
  const container = useRef(null);

  const scale = useTransform(progress, range, [1, targetScale]);
  return (
    <div ref={container} className="sm:mt-[100px] xs:mt-10">
      <motion.div
        style={{
          scale,
          top: `calc(-5vh + ${i * 25}px)`,
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:bg-[url('/Assets/Images/Others/card_analysis.webp')] bg-bottom bg-cover sm:bg-center bg-[url('/Assets/Images/Others/card_analysis_mobile.webp')] rounded-[40px] px-6 xs:px-6 md:p-12 xl:px-[72px] lg:px-6 pb-0 pt-6 lg:py-0 h-auto lg:h-[500px]">
          <div className="flex flex-col justify-center items-start">
            <CardTitle1 />
            <NextLink href={PATHS_NAME.FORECAST} target="_blank">
              <button className="rounded-full mt-6 md:mt-8 lg:mt-[30px] h-12 px-7 py-3 text-white bg-clever-primary flex items-center gap-2 md:gap-3">
                <Typography.Content className="font-semibold text-base">
                  {localesText("home.try_it_now")}
                </Typography.Content>
              </button>
            </NextLink>
          </div>

          <div className="flex justify-center items-center">
            <LottieImage
              options={{
                animationData: CardAnalysis,
                rendererSettings: {
                  className: "w-full",
                },
              }}
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default memo(AnalysisCard);
