import { memo } from "react";

import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NormalImage } from "Components/Lib";
import superChargeIcon from "Assets/Svg/supercharge.svg";

const TitleComponent = () => {
  return (
    <div className="flex flex-col items-start lg:items-center text-left ">
      <div className="flex flex-col lg:flex-row">
        <Typography.SectionTitle className="inline-block">
          {localesText("home.ai_data_analysis")}
        </Typography.SectionTitle>
        <Typography.SectionTitle className="lg:ml-2 lg:inline-block hidden">
          {localesText("home.analyst_to")}
        </Typography.SectionTitle>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex">
          <Typography.SectionTitle className="inline-block lg:hidden md:mr-3 mr-1 w-max">
            {localesText("home.analyst_to")}
          </Typography.SectionTitle>
          <div className="relative sm:inline-flex flex lg:ml-2 flex-col mr-2">
            <div className="border-gradient rounded-full py-2 -rotate-[4deg] bg-[radial-gradient(97.57%_210.75%_at_0.9%_2.98%,rgba(111,255,246,0.25)_0%,rgba(143,209,245,0.125)_76.56%,rgba(56,183,255,0.066)_95.31%)] md:px-4 px-2 text-[26px] leading-[36px] md:text-[56px] md:leading-[68px]">
              {localesText("home.supercharge")}
            </div>

            <div className="absolute md:top-[5px] md:-ml-2 top-[-2px] -ml-[5px]">
              <NormalImage
                src={superChargeIcon.src}
                alt="ios"
                className="w-[22px] md:w-[32px]"
              />
            </div>
          </div>
        </div>

        <Typography.SectionTitle className="inline-flex">
          {localesText("home.your_business")}
        </Typography.SectionTitle>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
