import OneStopFx from "Assets/Lotties/home_one_stop.json";
import { RotateHighlightText, Typography } from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo } from "react";
import { localesText } from "Utils/LocalesText.Util";

const OneStop = () => {
  return (
    <div className="flex flex-col-reverse xl:flex-row gap-16 items-center">
      <div className="max-w-[600px]">
        <LottieImage options={{ animationData: OneStopFx }} />
      </div>

      <div className="flex flex-1 flex-col gap-6 justify-center">
        <div className="text-left">
          <Typography.SectionTitle>
            <div className="max-w-[469px]">
              <span className="mr-2">{localesText("home.one_stop")}</span>
              <RotateHighlightText
                content={localesText("home.fx")}
                className="inline-block"
              />
              <span className="inline-block">
                ({localesText("home.coming_soon")})
              </span>
            </div>
          </Typography.SectionTitle>
        </div>

        <div className="lg:pr-11 text-clever-gray">
          <Typography.Body>
            {localesText("home.hello_clever_offer")}
          </Typography.Body>
        </div>
      </div>
    </div>
  );
};

export default memo(OneStop);
