import { memo } from "react";

import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="mb-5 flex flex-col gap-4 items-center max-w-[1096px] text-white text-center">
      <Typography.Giant className="max-w-[950px] text-center text-white">
        {localesText("home.it_pays_clever")}
      </Typography.Giant>

      <Typography.Body className="max-w-[916px] text-center sm:!text-lg">
        {localesText("home.all_in_one")}
      </Typography.Body>

      {/* <Typography.Body className="inline lg:inline-block">
          {localesText("home.download")}
        </Typography.Body> */}
    </div>
  );
};

export default memo(TitleComponent);
