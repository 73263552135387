import { NextLink } from "Components/Lib/Next";
import { Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { memo } from "react";
import { classnames } from "Utils/Classnames.Util";
import { localesText } from "Utils/LocalesText.Util";

interface Props {
  className?: string;
}

const ShopperButton = ({ className }: Props) => {
  return (
    <NextLink href={PATHS_NAME.SHOPPERS}>
      <button
        className={classnames(
          "bg-clever-black rounded-full flex w-fit border pl-5 pr-1 py-1",
          className,
        )}
      >
        <div className="text-white text-left flex justify-between">
          <div className="flex flex-col justify-center mr-3">
            <Typography.Content className="font-semibold text-dark">
              {localesText("home.are_you_shopper")}
            </Typography.Content>

            <Typography.Small className="text-[10px] text-dark">
              {localesText("home.see_how")}
            </Typography.Small>
          </div>

          <img
            src="Assets/Svg/arrow_right_black.svg"
            alt="arrow"
            width={48}
            height={48}
            className="relative z-10"
          />
        </div>
      </button>
    </NextLink>
  );
};

export default memo(ShopperButton);
