import { SectionWrap } from "Components/Layouts";
import TitleComponent from "./Title.Component";
import ParallaxCards from "./ParallaxCards";

const Section9 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-5 md:mb-[120px] mb-[84px]",
      }}
    >
      <TitleComponent />
      <ParallaxCards />
    </SectionWrap>
  );
};

export default Section9;
