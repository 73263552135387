"use client";
import { useScroll } from "framer-motion";

import AnalysisCard from "./AnalysisCard";
import PerformanceCard from "./PerformanceCard";
import InsightCard from "./InsightCard";
import { useRef } from "react";

export default function TestFile() {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const cards = [AnalysisCard, PerformanceCard, InsightCard];

  return (
    <div ref={container} className="relative ">
      {cards.map((Card, i) => {
        const targetScale = 1 - (cards.length - i) * 0.06;
        return (
          <Card
            key={`p_${i}`}
            progress={scrollYProgress}
            range={[i * 0.25, 1]}
            i={i}
            targetScale={targetScale}
          />
        );
      })}
    </div>
  );
}
